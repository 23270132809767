<!-- 历史版本介绍 -->
<template>
  <div class="do-page-versions">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="历史版本介绍">
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-versions-content">
        <div>
          <base-nodata
            v-if="list.length === 0"
            description="暂无历史版本数据"
          ></base-nodata>
          <div class="list-wrap" v-for="item in list" :key="item.version">
            <div class="title">E客流 v {{ item.version }}</div>
            <div class="time">{{ item.release_date }}</div>
            <div class="list-wrap-content">
              <div
                v-for="(jtem, jidx) in item.content"
                :key="jidx"
                class="item"
              >
                {{ jidx + 1 }}. {{ jtem }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { getVersionList } from "@/axios/common";
import { Toast } from "vant";
import { onMounted, ref } from "vue";
const router = useRouter();
const list = ref([]);
onMounted(() => {
  getVersionListData();
});

async function getVersionListData() {
  try {
    // 登录类型，写死 1
    const res = await getVersionList();
    console.log("resasfasf", res.data.list);
    if (res.data && res.data.list) {
      list.value = res.data.list;
    }
  } catch (error) {
    if (error) {
      Toast({
        message: error.msg || "登陆失败，未知错误",
      });
    }
  }
}
</script>

<style lang="scss">
.do-page-versions {
  background: #ffffff;

  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: #ffffff;
  }
  &-content {
    padding: 0 0.5rem !important;
    position: relative;
    .list-wrap {
      padding-top: 0.42rem;
      height: auto;
      .title {
        font-size: 0.3rem;
        font-weight: 500;
      }

      .more-btn {
        text-decoration: underline;
        font-size: 0.28rem;
        font-weight: 400;
        color: #0057ff;
      }
      .time {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }

      &-content {
        margin-top: 0.24rem;
        padding-bottom: 0.4rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.65);
        @include border-bottom(rgba(3, 5, 15, 0.12));

        .item {
          margin-bottom: 0.04rem;
        }
      }
    }
  }
}
</style>
